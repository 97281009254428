<template>
  <div class="d-flex justify-content-center mt-3"></div>
  <div class="d-grid gap-4">
    <a class="btn btn-primary btn-lg" @click="directToNewCount()">
      <i class="material-icons button-material-icon">play_arrow</i>
      {{ $englishPreferred ? "Start new count" : "Start ny tælling" }}
    </a>
    <a @click="directToCompletedCount()" class="btn btn-outline-primary btn-lg">
      {{
        $englishPreferred ? "Add completed count" : "Indtast gennemført tælling"
      }}
    </a>
    <router-link to="/taelling/mine" class="btn btn-primary btn-lg">
      <i class="material-icons button-material-icon">format_list_bulleted</i>
      {{ $englishPreferred ? "My counts" : "Mine tællinger" }}
    </router-link>
    <router-link to="/nedtaeller" class="btn btn-outline-primary btn-lg">
      <i class="material-icons button-material-icon">timer</i>
      {{ $englishPreferred ? "Countdown" : "Nedtællingsur" }}
    </router-link>
  </div>
</template>

<script>
import siteRepository from "../idb/repositories/siteRepository";
import specieRepository from "../idb/repositories/specieRepository";
import primaryBehaviourRepository from "../idb/repositories/primaryBehaviourRepository";

export default {
  name: "spontanMenu",
  methods: {
    async directToNewCount() {
      let siteCount = await siteRepository.getSitesCount();
      let speciesCount = await specieRepository.getSpeciesCount();
      let primaryBehaviourCount = await primaryBehaviourRepository.getPrimaryBehaviourCount();
      if (
        siteCount != null &&
        siteCount > 0 &&
        speciesCount != null &&
        speciesCount > 0 &&
        primaryBehaviourCount != null &&
        primaryBehaviourCount > 0
      ) {
        this.$router.push({ path: "/taelling/ny" });
      } else {
        this.reloadLocalAppData();
      }
    },
    async directToCompletedCount() {
      let siteCount = await siteRepository.getSitesCount();
      let speciesCount = await specieRepository.getSpeciesCount();
      let primaryBehaviourCount =
        await primaryBehaviourRepository.getPrimaryBehaviourCount();
      if (
        siteCount != null &&
        siteCount > 0 &&
        speciesCount != null &&
        speciesCount > 0 &&
        primaryBehaviourCount != null &&
        primaryBehaviourCount > 0
      ) {
        this.$router.push({ path: "/taelling/indtast" });
      } else {
        this.reloadLocalAppData();
      }
    },
    reloadLocalAppData() {
      console.log("No sites found, local data seems out of sync..");
      // set app out of sync, by removing cookie, then reload the page so the sync will run
      $cookies.remove("synched");
      location.reload();
    },
  },
};
</script>
