<template>
  <div class="container mt-2">
    <div class="card" v-if="!hideCard">
      <div
        class="card-body d-flex justify-content-between align-items-center pb-2"
      >
        <h5 class="card-title">
          <i class="material-icons button-material-icon">tips_and_updates</i>
          {{ selectedData.title }}
        </h5>
        <a class="close" @click="hideCard = true">
          <span>&times;</span>
        </a>
      </div>
      <div class="card-body pt-0">
        <p class="card-text mb-1">{{ selectedData.text }} <router-link
          v-if="selectedData.link"
          :to="selectedData.link"
          >{{ selectedData.linkText || "Læs mere" }}</router-link
        >
        <a target="_blank"
          v-if="selectedData.externalLink"
          :href="selectedData.externalLink"
          >{{ selectedData.linkText || "Læs mere" }}</a
        ></p>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          title: "Vidste du?",
          text: "Spontantællinger er såkaldte ”complete lists”, hvor alle sete og hørte individer, som kan bestemmes, skal registreres. Læs mere under ”Praktisk udførelse af spontantælling” i vejledningen ",
          link: "/vejledning",
          linkText: "her",
        },
        {
          title: "Vidste du?",
          text: "Påbegynd ikke en tælling foranlediget af en sjælden eller særlig interessant observation",
        },
        {
          title: "Vidste du?",
          text: "Pointene i Spontantællingernes spiltilstand er baseret på antallet af dage siden seneste tælling i hvert af de 2255 kvadrater. Du kan se dine point under ",
          link: "/statistik",
          linkText: "Min Statistik",
        },
        {
          title: "Tip",
          text: "Det er valgfrit at deltage i spiltilstand i Spontantællinger. Slå spiltilstand til og fra under ",
          link: "/indstillinger",
          linkText: "Indstillinger",
        },
        {
          title: "Vidste du?",
          text: "Spontantællinger bidrager bl.a. til at afdække fuglenes forekomst hen over året",
        },
        {
          title: "Tip:",
          text: "Spontantællinger foregår efter en bestemt metode. Ønsker du at indtaste observationer efter anden metode kan du gøre det på www.dofbasen.dk",
          link: "/vejledning",
        },
        {
          title: "Vidste du?",
          text: "Spontantællinger kan bruges til at opdage pludselige sygdomsudbrud blandt vilde fugle",
        },
        {
          title: "Tip",
          text: "Har du lige fem minutter? Husk at du kan lave spontantælling næsten hvor som helt og når som helst",
        },
        {
          title: "Tip",
          text: "Missede du toget? Så kan du måske lige nå en spontantælling :-)",
        },
        {
          title: "Vidste du?",
          text: "Spontantællinger skal laves i ”godt vejr”. Undgå f.eks. regn og tæt tåge. Læs mere i vejledningen ",
          link: "/vejledning",
          linkText: "her",
        },
        {
          title: "Tip",
          text: "Hvis du er bidt af spontantællinger, så er punkttællinger måske også noget for dig. Læs mere ",
          externalLink: "https://www.dof.dk/fakta-om-fugle/punkttaellingsprogrammet",
          linkText: "her",
        },
        {
          title: "Vidste du?",
          text: "Du må gerne indtaste observationer, efter de 5 minutter er gået, så længe obsen er gjort inden for de 5 minutter",
        },
        {
          title: "Vidste du?",
          text: "Du kan indtaste sekundær adfærd som fx ”syngende” i feltet ”Valgfrit”",
        },
        {
          title: "Vidste du?",
          text: "Du kan rette en observation (fx for højt antal) ved at klikke på artsnavnet i listen over indtastninger",
        }
      ],
      selectedData: {},
      hideCard: false,
    };
  },
  mounted() {
    // Select a random row from the data array
    this.selectedData = this.data[Math.floor(Math.random() * this.data.length)];
  },
};
</script>

<style scoped>
/* Add any custom styles here */
h5 {
  color: #51af30;
}

h5 {
  color: #51af30;
  margin-bottom: 0;
}
.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  margin-left: 1rem;
}
.close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}
</style>
