<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <nav-header></nav-header>
    <div class="container">
      <div class="d-flex justify-content-center mt-4">
        <div class="row">
          <div class="col-6 offset-3">
            <img
              class="img-fluid"
              style="max-height: 350px"
              src="@/assets/doflogo.png"
            />
          </div>
        </div>
      </div>
      <PWAInstallPromp />
      <FrontPageTipBox></FrontPageTipBox>
      <SpontanMenu />
      <div class="alert alert-primary mt-3" role="alert">
        <i class="material-icons button-material-icon">notifications</i>
        <b>Version 1.0.25</b><br />
        Velkommen til DOF Spontantællinger.<br />
        Feedback og fejlrapporter modtages via mail til: Thomas Vikstrøm
        (thomas.vikstroem@dof.dk) og Daniel Palm Eskildsen (dpe@dof.dk) :-)<br />
        Der kan forekomme ændringer i point for indtastede tællinger.<br />
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "leaflet/dist/leaflet.css";
import PWAInstallPromp from "@/components/PWAInstallPrompt";
import SpontanMenu from "@/components/SpontanMenu";
import NavHeader from "@/components/navbars/NavHeader.vue";
import synchronizationService from "../synchronization/synchronizationService";
import FrontPageTipBox from "../components/FrontPageTipBox.vue";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { ref } from "vue";

export default {
  name: "Home",

  components: {
    PWAInstallPromp,
    SpontanMenu,
    NavHeader,
    Loading,
    FrontPageTipBox,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async handleSynch() {
      this.isLoading = true;
      console.log("isLoading");
      await synchronizationService.restoreOfflineDataByStoreIfNeededOrDoBackup();
      await synchronizationService.runSynchIfNeeded();
      this.isLoading = false;
      console.log("done loading");
    },
  },
  mounted() {
    // Determine if mandatory local storage data is up to date, and refresh if it isn't
    this.handleSynch();
  },
};
</script>
